import Separator from "@/shared/Separator";
import { ParentGrid, Card } from "./style";
import { GridStyleColor } from "@/widgets/Container/Templates/stylesGridGeneric.js";
const ContainerFama = (props) => {
  const { link, sectionClass = "", title, linkImage } = props;
  const defaultTemplate = [
    "OnexTwo",
    "OnexTwo",
    "OnexOne",
    "OnexOne",
    "OnexOne",
    "OnexOne",
  ];
  const items = [];
  for (let index = 0; index < 6; index++) {
    items.push(
      <Card className={`div${index + 1}`} key={`container${index + 1}`}>
        {props.renderContainerWidgetCallback(
          index + 1,
          props.positionProperties?.[index]?.templateNews ?? defaultTemplate[index], 
          { containerName: "contenedor_fama" }
        )}
      </Card>
    );
  }
  const marfeelTitle = title
    ? "ContainerFama-" +
      title
        ?.normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/\s+/g, "-")
        .toLowerCase()
    : "ContainerFama";
  return (
    props.content?.length > 0 && (
      <>
        {title && (
          <Separator
            title={title}
            link={link}
            sectionClass={sectionClass}
            linkImage={linkImage}
          />
        )}
        <GridStyleColor>
          <ParentGrid
            data-mrf-recirculation={`${marfeelTitle}`}
            className={`ContainerFama module-grid ${sectionClass}`}
          >
            {items}
          </ParentGrid>
        </GridStyleColor>
      </>
    )
  );
};
export default ContainerFama;
